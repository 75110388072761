.dropdown-menu>li>a {
  padding: 8px 30px !important;
  color: black !important;
}

.dropdown-menu>li>a:hover {
  background: @brand-primary; // yellow = fed136
  color: white !important;
}

.navbar {
  min-height: 70px;
}

.navbar-nav {
  margin-top: 10px;

  // Animation
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.navbar-brand {
  margin-top: 10px;

  // Animation
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.navbar-shrink {
  min-height: 50px;
}

.navbar-shrink {
  min-height: 50px;

  .navbar-nav {
    margin-top: 0px;

    // Animation
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }

  .navbar-brand {
    margin-top: 0px;

    // Animation
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
}

.navbar {
  // Animation
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}