// Description Blocks
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
  &.margin-bottom {
    margin-bottom: 25px;
  }
  > .description-header {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
  }
  > .description-text {
    text-transform: uppercase;
  }
}