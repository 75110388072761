
//border radius creator
.border-radius(@radius) {
  border-radius: @radius;
}

//Different radius each side
.border-radius(@topleft;  @topright; @bottomleft; @bottomright)
{
  border-top-left-radius: @topleft;
  border-top-right-radius: @topright;
  border-bottom-right-radius: @bottomright;
  border-bottom-left-radius: @bottomleft;
}

// Single side border-radius
.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}