.panel-subnav {
  border-color: #3674A3;
}
.panel-subnav > .panel-heading {
  color: #fff;
  background-color: #3674A3;
  border-color: #3674A3;
}
.panel-subnav > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #3674A3;
}
.panel-subnav > .panel-heading .badge {
  color: #3674A3;
  background-color: #fff;
}
.panel-subnav > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #3674A3;
}

.panel-subnav{
  border-bottom:0;
  border-left:0;
  border-right:0;
}
.panel.with-nav-tabs .panel-heading{
  padding: 5px 5px 0 5px;
}
.panel.with-nav-tabs .nav-tabs{
  border-bottom: none;
}
.panel.with-nav-tabs .nav-justified{
  margin-bottom: -1px;
}