@font-face {
  font-family: "icofit";
  src: url("/public/fonts/icofit.woff") format('woff');
}

.icofit {
  display: inline-block;
  font: normal normal normal 14px/1 icofit;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@colour-group-running: @sport-blue;
@colour-group-walking: @sport-green;
@colour-group-cycling: @sport-red;
@colour-group-swimming: @sport-purple;
@colour-group-rowing: @sport-aqua;
@colour-group-fitness: @sport-burgundy;
@colour-group-sport: @sport-orange;
@colour-group-other: @sport-yellow;

.icofit-round {
  border-radius: 100%;
  padding: 4px;
}
.icofit-square {
  padding: 4px;
}

.icofit-1-25x {
  font-size: 1.25em;
  padding: 4px;
}
.icofit-1-5x {
  font-size: 1.5em;
  padding: 6px;
}
.icofit-2x {
  font-size: 2em;
  padding: 8px;
}
.icofit-3x {
  font-size: 3em;
  padding: 10px;
}
.icofit-4x {
  font-size: 4em;
  padding: 12px;
}
.icofit-5x {
  font-size: 5em;
  padding: 14px;
}

.icofit-plain {
  background-color: transparent !important;
}

.icofit-cycle,
.icofit-exbike {
  background-color: @colour-group-cycling;
  color: @white;
}


.icofit-run {
  background-color: @colour-group-running;
  color: @white;
}

.icofit-hike,
.icofit-walk-left,
.icofit-walk {
  background-color: @colour-group-walking;
  color: @white;
}

.icofit-treadmill {
  background-color: lighten(@colour-group-running, 13.5%);
  color: @white;
}

.icofit-swim {
  background-color: @colour-group-swimming;
  color: @white;
}

.icofit-rblade,
.icofit-skate,
.icofit-golf,
.icofit-tennis,
.icofit-ski {
  background-color: @colour-group-sport;
  color: @white;
}

.icofit-xtrain,
.icofit-climb,
.icofit-yoga {
  background-color: @colour-group-fitness;
  color: @white;
}

.icofit-coxing,
.icofit-rowmach,
.icofit-row {
  background-color: @colour-group-rowing;
  color: @white;
}

.icofit-bdance {
  background-color: @colour-group-other;
  color: @white;
}


.icofit-cycle:before {
  content: "\e900";
}
.icofit-rblade:before {
  content: "\e901";
}
.icofit-skate:before {
  content: "\e902";
}
.icofit-swim:before {
  content: "\e903";
}
.icofit-golf:before {
  content: "\e904";
}
.icofit-tennis:before {
  content: "\e905";
}
.icofit-climb:before {
  content: "\e906";
}
.icofit-hike:before {
  content: "\e907";
}
.icofit-run:before {
  content: "\e908";
}
.icofit-walk-left:before {
  content: "\e909";
}
.icofit-ski:before {
  content: "\e90a";
}
.icofit-coxing:before,
.icofit-rowmach:before,
.icofit-row:before {
  content: "\e90b";
}
.icofit-walk:before {
  content: "\e90c";
}
.icofit-yoga:before {
  content: "\e90d";
}
.icofit-xtrain:before,
.icofit-treadmill:before {
  content: "\e90e";
}
.icofit-exbike:before {
  content: "\e90f";
}
.icofit-bdance:before {
  content: "\e90f";
}