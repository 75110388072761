.container-round {
  border-radius: @border-radius-base;
}
.container-round-bottom {
  border-bottom-right-radius: @border-radius-base;
  border-bottom-left-radius: @border-radius-base;
}

.container-notop {
  margin-top:0px;
  padding-bottom:15px;
  padding-top: 15px;
}

.container-white {
  background-color: white;
}

.container-full {
  padding-left:0;
  padding-right:0;
  padding-top:15px !important;
  padding-bottom: 15px !important;
}

.wrap-container {
  margin-top: 15px;
  z-index: 1000;
  //margin-top: -40px;
  //box-shadow: 0 4px 0 #444;
  //position:relative;
  //padding-top: 15px;
}