@import "agency.less";

html {

}

body {
   background-color: #ddd;
 }

h1, .h1 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.navbar-race .nav li a:hover,
.navbar-race .nav li a:focus {
  color: @black;
}

.full-bg {
  // TODO: Fix url path
  background: url('/assets/images/default/bg4.png') no-repeat center center fixed;
}

.jumbotron h1 {
  color: white;
}

.jumbotron .bg {
  box-shadow: 10px 0 0 rgba(0,0,0,0.7),-10px 0 0 rgba(0,0,0,0.7);
  background-color: rgba(0,0,0,0.7);
  padding-bottom: 5px;
}

.jumbotron {
  color: white;
  background-size: cover;
}

@media (min-width: 768px) and (max-width: 991px) {
  .context-nav {
    margin-top: 120px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .context-nav {
    margin-top: 120px !important;
  }
}

.admin-button {
  margin-top: 3px;
  margin-right: 6px;
}