.context-nav {
  //text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;

  margin-top:70px;
  height: 40px;
  background-color: @primary;
}

.context-nav-small {
  height: 15px;
}

.context-nav {

  .nav-pills {
    a {
      background-color: transparent;
      color: white;
    }

    .active a {
      background-color: @navbar-inverse-bg;
    }
  }

  .active:after {
    content: ' ';
    position: absolute;
    border: solid 10px transparent;
    border-top: solid 0px transparent;
    border-width: 10px;
    left: 50%;
    margin-left: -10px;
    top: 20px;
    border-color: transparent transparent white transparent;
  }
}

@media(min-width:768px) {
  .active:after {
    margin-left: 0px;
  }
}

.centered-pills {
  text-align: center
}
.centered-pills ul.nav-pills {
  display: inline-block
}
.centered-pills li {
  display: inline
}
.centered-pills a {
  float: left
}
* html .centered-pills ul.nav-pills, *+html .centered-pills ul.nav-pills {
  display: inline
}

