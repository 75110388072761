.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.tbl-place {
    width: 10px;
}

th {
    .bg-gray {
        color: #000;
        background-color: @brand-primary !important;
    }

    .bg-gray-light {
        background-color: #eeeeee !important;
    }

    .bg-black {
        background-color: @brand-primary !important;
    }

    .bg-red {
        background-color: @brand-primary !important;
    }

    .bg-yellow {
        background-color: @brand-primary !important;
    }
}

.table-activities > tbody > tr > td
{
    vertical-align:middle;
}

.table-activities > tbody > tr > td:first-child {
    border-right: none;
}

.table-activities  .event-logo {
    margin: 0;
}

.table-activities th {
    background-color: #d9edf7; //@primary-light;
    //color: @lightest;
}