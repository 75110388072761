.pageheader {
  margin-top: 0px;
  padding: 0;
}

.pageheader-default {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain !important;
  //background: url('/test/running.jpg');
  //color: @pageheader-primary;
  background-attachment: fixed;
}

.pageheader-default .semitransparentbg {
  background-color: @pageheader-bg;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 4px solid #eee;
  margin-bottom: 15px;
}

//.pageheader-default .semitransparentbg, .bgarea-semitransparent {
//  background: rgba(245,72,40,0.80) !important;
//}
//
//.pageheader-default .semitransparentbg {
//  padding-bottom: 50px;
//}
