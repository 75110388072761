.fc-event, .fc-event:hover {
  background-color: @lightest;
  border: 0;
  color: black;
}
.fc-content {
  display: flex;
  flex-direction: row;
}
.event-logo {
  margin-left: 5px;
  margin-right: 5px;
  margin: 5px;
}
.event-distance {
  font-size: 1.25em;
  font-weight: bold;
}
.event-description {
  display: flex;
  align-items: center;
}
.fc-day-header {
  background-color: @primary;
  color: @primary-header-text;
}
