.bg-dark-overlay {
  background: rgba(0, 0, 0, 0.2);
}

.bg-primary-light {
  color: @lightest;
  background-color: @brand-primary-light !important;
}
.bg-success-light {
  color: @lightest;
  background-color: @brand-success-light !important;
}
.bg-info-light {
  color: @lightest;
  background-color: @brand-info-light !important;
}
.bg-warning-light {
  color: @lightest;
  background-color: @brand-warning-light !important;
}
.bg-danger-light {
  color: @lightest;
  background-color: @brand-danger-light !important;
}

// Background colors
.bg-black,
.bg-gray
.bg-gray-light

.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-burgundy,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active {
  color: @white !important;
}

.bg-white {
  color: @black;
  background-color: @white !important;
}
.bg-gray {
  color: @white;
  background-color: @gray !important;
}

.bg-gray-light {
  color: @black;
  background-color: @gray-lighter !important;
}

.bg-black {
  color: @white;
  background-color: @black !important;
}

/* Colors */
.bg-red {
  color: @white;
  background-color: @red !important;
}
.bg-yellow {
  background-color: @yellow !important;
}
.bg-blue {
  background-color: @blue !important;
}
.bg-light-blue {
  background-color: @light-blue !important;
}
.bg-aqua {
  background-color: @aqua !important;
}
.bg-green {
  background-color: @green !important;
}
.bg-navy {
  background-color: @navy !important;
}
.bg-teal {
  background-color: @teal !important;
}
.bg-burgundy {
  background-color: @burgundy !important;
}
.bg-olive {
  background-color: @olive !important;
}
.bg-lime {
  background-color: @lime !important;
}
.bg-orange {
  background-color: @orange !important;
}
.bg-fuchsia {
  background-color: @fuchsia !important;
}
.bg-purple {
  background-color: @purple !important;
}
.bg-maroon {
  background-color: @maroon !important;
}

