footer {
  background-color: black;
  color: white;
  margin-top: 15px;
  padding-top: 25px;
  padding-bottom: 25px;

  box-shadow: 10px 0 0 rgba(0,0,0,0.7),-10px 0 0 rgba(0,0,0,0.7);
  background-color: rgba(0,0,0,0.7);

  //height: 100px;
  //margin-top: -40px;
  //padding-top: 60px;
  //z-index: -1;
}

footer a, footer a:hover {
  color: @brand-info-light; //@navbar-inverse-bg;
}